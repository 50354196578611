import logo from './logo.svg';
import React, {useState} from 'react';
import './App.css';

function App() {
const [input, setInput] = useState("");
const [src, setSrc] = useState("");
  const handleInput = (e)=>{
    let value = e.target.value;
    console.log(value)
    setInput(value)
  }
  function handleSubmit(e){
    // let iframe = document.getElementById("iframe_a")
    console.log(input);
    console.log("first")
    setSrc(input);
    // e.preventDefault();
  }
  return (
    <div className="container">
      <div className='nav'>Project S C</div>
      <div className="text-div">
      <textarea id="w3review" name="w3review" style={{width:"80%"}} placeholder="feed me Alpyne url " onChange={(e)=>handleInput(e)} />
      </div>
      <div className="submit-b">

      <button className="submit-button" onClick={handleSubmit}>submit</button>
      </div>
     {src.length > 250  ? 
      <div className="iframe-div">

      <iframe src={src} allow="camera *;microphone *" name="iframe_a" height="600px" width="95%" title="Iframe Example" ></iframe>

      </div>
      : <></> } 
    </div>
  );
}

export default App;
